.App {
  text-align: center;
  background-color: #1E1C1D;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: #1E1C1D;
}

.Title {
  background: -webkit-linear-gradient(top, rgb(76, 254, 212) 75%, rgba(230, 96, 0, 0.9) 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-size: 5vw;
  padding: 6vw 15vw;
  padding-bottom: 0vw;
}

.Description {
  color: whitesmoke;
  text-align: left;
  font-size: 2vw;
  padding: 6vw 15vw;
}

.ContactDescription {
  color: whitesmoke;
  text-align: left;
  font-weight: 100;
  font-size: 1.3vw;
  padding: 6vw 15vw;
}

@media (max-width: 700px) {
  .Description {
    color: whitesmoke;
    text-align: left;
    font-size: 5vw;
    padding: 6vw 6vw;
  }

  .Title {
    background: -webkit-linear-gradient(top, rgb(36 255 241) 30%, rgba(230, 96, 0, 0.9) 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12vw;
    text-align: left;
    padding: 3vw 6vw;
  }

  .ContactDescription {
    color: whitesmoke;
    text-align: left;
    font-size: 20px;
    padding: 60px 30px;
  }
}

.email {
  color: #17b6a9;
  background: none;
  border: none;
  font-weight: 100;
  font-size: 1.3vw;
  cursor: pointer;
  font-family: 'Times New Roman', Times, serif;
}

/* .aboutMeDescription{
  font-size: medium;
} */
