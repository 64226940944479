/* .navbar {
    height: 60px;
    background: #1E1C1D;
    justify-content: space-between;
    display: flex;
    padding: 30px 70px 0% 0%;
    align-self: center;
    position: relative;
} */

/* .navbar > .toggleButton > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
} */

/* .Title {
    color: #a6b617;
} */

.fa-bars {
    font-size: 20px;
    background-color: #1E1C1D;
    color: #17b6a9;
}

.fa-bars:hover {
    font-size: 20px;
    background-color: #1E1C1D;
    color: rgb(59, 226, 217);
}

.navbar > .links > a {
    font-family: 'SP-regular';
    text-decoration: none;
    margin: 10px;
    font-size: 20px;
    color: #17b6a9;
}

.navbar > .links > a:hover {
    font-family: 'SP-regular';
    text-decoration: none;
    margin: 10px;
    font-size: 20px;
    color: rgb(59, 226, 217);
}

.navbar > .toggleButton {
    display: none;
}

@font-face {
    font-family: SP-regular;
    src: url('SourceSansPro-Regular.ttf') format('truetype');
}

/* @media (max-width: 700px) {
    .navbar > .links {
        position: absolute;
        top: 60px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .navbar > .toggleButton {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .navbar > .toggleButton > .fa-bars {
        position: relative;
        align-items: center;
        font-size: 36px;
        justify-content: center;
    }
} */

